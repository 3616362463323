
import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";

export const Skills = () => {
  return (
    <section className="experience" id="experience">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="experience-bx wow zoomIn">
              <h2 className="text-center experience-title">Experience</h2>
              <VerticalTimeline>
                
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #667eea' }}
                  // date="May 2024 - present"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)', color: '#fff' }}
                  icon={<img src={arrow1} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Uber</h3>
                  <h4 className="vertical-timeline-element-subtitle">Platform SWE Intern</h4>
                  <h4 className="date">May 2024 - Present | Sunnyvale, CA</h4>
                  <h4 className="text-exp">Training ML models to optimize cluster efficiency of Uber's Presto Query Engine for batch processing.</h4>

                  {/* <p>
                    Training ML models to optimize cluster efficiency of Uber's Presto Query Engine for batch processing.
                  </p> */}
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #764ba2' }}
                  // date="2020 - 2021"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow2} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Upfront</h3>
                  <h4 className="vertical-timeline-element-subtitle">Fullstack SWE Intern</h4>
                  <h4 className="date">Sept 2023 - Dec 2023 | San Francisco, CA</h4>
                  <h4 className="text-exp">- Fullstack dev at Y-Combinator backed startup helping merchants lower out of pocket costs of clean energy products. From finding and handling incentives to offering financing, we provide merchants with the tools to always offer the best deal in town.</h4>
                  <h4 className="text-exp">
                      - Working on <a href="https://docs.knowupfront.com/reference/upfront-pay" target="_blank" rel="noopener noreferrer" className="upfront-pay-link">Upfront Pay</a> and <a href="                    https://www.knowupfront.com/
" target="_blank" rel="noopener noreferrer" className="upfront-pay-link">more</a>!
                    </h4>

                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow1} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">UW Orbital</h3>
                  <h4 className="vertical-timeline-element-subtitle">Firmware Developer</h4>
                  <h4 className="date">Present | Waterloo, ON</h4>
                  <h4 className="text-exp">  <a href="https://www.uworbital.com/" target="_blank" rel="noopener noreferrer" className="upfront-pay-link">Satellite design team</a> developing a CubeSat - C/FreeRTOS</h4>
                
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow2} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Women In Eng</h3>
                  <h4 className="vertical-timeline-element-subtitle">Outreach Director</h4>
                  <h4 className="date">May 2023 - Aug 2023 | Waterloo, ON</h4>
                  <h4 className="text-exp">Creating engineering initiatives for middle school/ high school girls</h4>
                
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow1} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Vivid Seats</h3>
                  <h4 className="vertical-timeline-element-subtitle">Backend SWE Intern</h4>
                  <h4 className="date">Jan 2023 - April 2023 | Toronto, ON</h4>
                  <h4 className="text-exp">Backend developer for the react native mobile <a href="https://corporate.vividseats.com/app/" target="_blank" rel="noopener noreferrer" className="upfront-pay-link">app</a></h4>

                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow2} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">CDK Global</h3>
                  <h4 className="vertical-timeline-element-subtitle">Backend SWE Intern</h4>
                  <h4 className="date">June 2022 - Aug 2022 | San Jose, CA</h4>
                  <h4 className="text-exp">Developed <a href="https://apidocs.fortellis.io/" target="_blank" rel="noopener noreferrer" className="upfront-pay-link">REST APIs</a> for automotive dealerships across US/Canada.</h4>
                  <h4 className="text-exp">- NodeJS, Express, AWS DynamoDB, Terraform, Postman, Bamboo</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow1} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Midnight Sun - Solar Car Design Team</h3>
                  <h4 className="vertical-timeline-element-subtitle">Software Developer</h4>
                  <h4 className="date">Jan 2022 - Sept 2022 | Waterloo, ON</h4>
                  <h4 className="text-exp">- Improved solar car power efficiency by creating models that calculate an efficiency score for different race loops. The models consider real-time data of the car and other factors like distance, speed limits, turns, and steepness.</h4>
                  <h4 className="text-exp">- Python, Pandas, Matplotlib, Numpy</h4>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #6a11cb' }}
                  // date="2019 - 2020"
                  dateClassName="timeline-date"
                  iconStyle={{ background: 'linear-gradient(135deg, #764ba2 0%, #667eea 100%)', color: '#fff' }}
                  icon={<img src={arrow2} alt="icon" style={{ width: '100%', height: '100%' }} />}
                >
                  <h3 className="vertical-timeline-element-title">Girl Up</h3>
                  <h4 className="vertical-timeline-element-subtitle">Founder & President</h4>
                  <h4 className="date">Aug 2019 - May 2021 | Cypress, CA</h4>
                  <h4 className="text-exp">I founded this organization in high school to further diversity in STEM by providing young women with unique tech opportunities. I raised $5,321 to bridge the gender gap by leading educational lectures, weekly meetings, and events involving 400+ students nationwide. Created Python workshops to enable young girls to learn CS fundamentals and build technical projects. Organized a conference with networking workshops, donation for girls’ education in Malawi, and guest speakers.</h4>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="background" />
    </section>
  );
}
