import { Col } from "react-bootstrap";
import { FaGithub } from 'react-icons/fa';

export const ProjectCard = ({ title, description, imgUrl, githubUrl, category }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} alt={title} />
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          {category !== "life" && githubUrl && (
            <div className="github-icon">
              <a href={githubUrl} target="_blank" rel="noopener noreferrer">
                <FaGithub size={30} />
              </a>
            </div>
          )}
        </div>
      </div>
    </Col>
  )
}
