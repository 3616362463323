


// import { useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";
// import 'animate.css';
// import TrackVisibility from 'react-on-screen';
// import meter1 from "../assets/img/meter1.svg";
// import meter2 from "../assets/img/meter2.svg";
// import meter3 from "../assets/img/meter3.svg";
// import Carousel from 'react-multi-carousel';
// import 'react-multi-carousel/lib/styles.css';
// import arrow1 from "../assets/img/arrow1.svg";
// import arrow2 from "../assets/img/arrow2.svg";
// import colorSharp from "../assets/img/color-sharp.png";

// export const Contact = () => {
//   const responsive = {
//     superLargeDesktop: {
//       // the naming can be any, depends on you.
//       breakpoint: { max: 4000, min: 3000 },
//       items: 5
//     },
//     desktop: {
//       breakpoint: { max: 3000, min: 1024 },
//       items: 3
//     },
//     tablet: {
//       breakpoint: { max: 1024, min: 464 },
//       items: 2
//     },
//     mobile: {
//       breakpoint: { max: 464, min: 0 },
//       items: 1
//     }
//   };

//   return (
//     <section className="contact" id="connect">
//       <Container>
//         <Row className="align-items-center">
//           <Col size={12}>
//             <TrackVisibility>
//               {({ isVisible }) =>
//                 <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
//                   <h2>Get In Touch</h2>
//                   <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
//                     <div className="item">
//                       <img src={meter1} alt="Web Development" />
//                       <h5>Web Development</h5>
//                     </div>
//                     <div className="item">
//                       <img src={meter2} alt="Brand Identity" />
//                       <h5>Brand Identity</h5>
//                     </div>
//                     <div className="item">
//                       <img src={meter3} alt="Logo Design" />
//                       <h5>Logo Design</h5>
//                     </div>
//                     <div className="item">
//                       <img src={meter1} alt="Web Development" />
//                       <h5>Web Development</h5>
//                     </div>
//                   </Carousel>
//                 </div>}
//             </TrackVisibility>
//           </Col>
//         </Row>
//       </Container>
//       <img className="background-image-left" src={colorSharp} alt="Background" />
//     </section>
//   );
// };



import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png";
// import '../assets/css/custom.css';  // Make sure to import your custom CSS

export const Contact = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="contact" id="connect">
      <Container>
        <Row className="align-items-center">
          {/* <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  <h2>Useful Courses</h2>
                  <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                    <div className="item">
                      <img src={meter1} alt="Web Development" className="dark-gradient" />
                      <h5>Web Development</h5>
                    </div>
                    <div className="item">
                      <img src={meter2} alt="Brand Identity" className="dark-gradient" />
                      <h5>Brand Identity</h5>
                    </div>
                    <div className="item">
                      <img src={meter3} alt="Logo Design" className="dark-gradient" />
                      <h5>Logo Design</h5>
                    </div>
                    <div className="item">
                      <img src={meter1} alt="Web Development" className="dark-gradient" />
                      <h5>Web Development</h5>
                    </div>
                  </Carousel>
                </div>}
            </TrackVisibility>
          </Col> */}
        </Row>
      </Container>
      <img className="background-image-left" src={colorSharp} alt="Background" />
    </section>
  );
};


