import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import cardHolder from "../assets/img/cardHolder.png";
import rocket from "../assets/img/rocket.png";
import projImg1 from "../assets/img/plannect-pic.png";
import car from "../assets/img/car.png";
import hike from "../assets/img/hike.png";
import dol from "../assets/img/dol.png";
import seq from "../assets/img/seq.png";
import upfront from "../assets/img/upfront.png";
import snow from "../assets/img/snow.png";
import dig from "../assets/img/dig.png";
import bt from "../assets/img/bt-pic.png";
import autoapply from "../assets/img/autoApply.png";
import sanguine from "../assets/img/san_pic.png";
import cyberprint from "../assets/img/cyberprint-pic.png";
import educate from "../assets/img/educate.png";








import projImg2 from "../assets/img/pp.png";
import projImg3 from "../assets/img/project-img3.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: "Plannect",
      description: "Won first place at Elle Hacks for web app that uses AI to connect users locally with others on shared passions. ReactJS/MapBox API frontend and backend built with Express/NodeJS, Autocode, GPT-3, Typeform Responses API to generate personalized matches.",
      imgUrl: projImg1,
      githubUrl: "https://github.com/riyapatel25/plannect",
    },
    {
      title: "Perfect Places Plugin",
      description: "Developed ChatGPT Plugin that curates a list of places based on real-time location and unique preferences. Integrated Google Maps API in endpoints made with Python/Flask to retrieve and analyze reviews. Plugin saves time by automating the process of endless scrolling through reviews to find the perfect place.",
      imgUrl: projImg2,
      githubUrl: "https://github.com/riyapatel25/googlePlacesPlugin"
    },
    {
      title: "BlockTalk",
      description: "Developed a social networking iOS/Android app with Flutter/Dart to connect interns in new cities. Implemented an interactive UI for user profile, activity feed, and liked places on maps using Google Maps API. Employed Firebase for user authentication + user data.",
      imgUrl: bt,
      githubUrl: "https://github.com/riyapatel25/BlockTalk",
    },
    {
      title: "Auto Apply Job Bot",
      description: "Developed a Python script which searches and applies to jobs based on user’s personal data and goals. Used BeautifulSoup to scrape relevant job listings on Glassdoor and aggregate the application URLs. Employed Selenium + Chromium/Webdriver to open 200+ postings and auto-fill applications in less than 30 mins.",
      imgUrl: autoapply,
      githubUrl: "https://github.com/riyapatel25/internship_bot",
    },
    {
      title: "DigiTalli",
      description: "Built an Arduino device that automates covid protocols by counting real-time occupancy. Analyzes a person’s eligibility to enter based on C++ algorithms I developed for motion/temperature sensors. Features: non-contact infrared thermometer that checks temperature as people walk in; when max cap is hit- a contactless system to collect phone numbers that notifes users upon availibilty",
      imgUrl: dig,
      githubUrl: "https://github.com/riyapatel25/digiTalli",
    },
    {
      title: "CyberPrint",
      description: "Website aimed to help users be digitally aware. With the increased digital usage caused by the pandemic, Cyberprint educates users on topics such as digital footprints and digital awareness. Cyberprint also has an interactive trivia game that tests users on their new knowledge.",
      imgUrl: cyberprint,
      githubUrl: "https://github.com/riyapatel25/Cyber-print",
    },
    {
      title: "Sanguine",
      description: "Built a ReactJS based web application that provides users with volunteer/donation resources to fight period poverty. Designed a responsive component-based frontend by implementing modular designs with MaterialUI. Developed a quiz to generate personalization and improve user experience by analyzing their resources and intentions.",
      imgUrl: sanguine,
      githubUrl: "https://github.com/riyapatel25/sanguine-website"
    },
    {
      title: "Educate",
      description: "Created an iOS mobile application with Swift dedicated to creating diversity in STEM by presenting minorities with targeted resources. Designed and programmed UI with X-Code which consolidates data such as location, grade, areas of interest to generate a personalized list of opportunities.",
      imgUrl: educate,
    },
    
  ];

  const life = [
    {
      title: "CAAP: Code as a passenger",
      // description: "A cool 3D printed trinket.",
      imgUrl: car,
    },
    {
      title: "Hiking",
      // description: "Another cool 3D printed trinket.",
      imgUrl: hike,
    },
   
    {
      title: "Dolores Park",
      // description: "Yet another cool 3D printed trinket.",
      imgUrl: dol,
    },
    {
      title: "Assembling whiteboards with the Upfront team",
      // description: "Yet another cool 3D printed trinket.",
      imgUrl: upfront,
    },
  
    {
      //sequioa
      title: "Sequoia",
      // description: "Yet another cool 3D printed trinket.",
      imgUrl: seq,
    },
   
    {
      title: "Snowboarding",
      // description: "Yet another cool 3D printed trinket.",
      imgUrl: snow,
    },
  ];

  const projectSet2 = [
    {
      title: "Rockets",
      description: "ignore the top missing from the pink one (ran out of fillement)",
      imgUrl: rocket,
      githubUrl: "https://github.com/riyapatel25/3d_files/blob/main/rocket.stl"
    },
    {
      title: "Card Holder",

      imgUrl: cardHolder,
    },

  ];
  
  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                  <h2>Gallery</h2>
                  <p>Some of my past projects, random 3d trickets I made, and life pics!</p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="first">Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">My 3D Trinkets</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">Life</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="first">
                        <Row className="fixed-size">
                          {
                            projects.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row className="fixed-size">
                          {
                            projectSet2.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {
                            life.map((project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="Background" />
    </section>
  )
}