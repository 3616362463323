


import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { FaLinkedin, FaTwitter, FaGithub } from 'react-icons/fa';
import { BrowserRouter as Router } from "react-router-dom";
import { RiTwitterXFill } from "react-icons/ri";


export const NavBar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }

  return (
    <Router>
      <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>Experience</Nav.Link>
              <Nav.Link href="#projects" className={activeLink === 'projects' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
            </Nav>
            <span className="navbar-text">
              <div className="social-icon">
                <a href="https://www.linkedin.com/in/riya-patel25/" target="_blank" rel="noopener noreferrer"><FaLinkedin color="white" size="1.5em" /></a>
                <a href="https://x.com/riyapee" target="_blank" rel="noopener noreferrer"><RiTwitterXFill color="white" size="1.5em" /></a>
                <a href="https://github.com/riyapatel25" target="_blank" rel="noopener noreferrer"><FaGithub color="white" size="1.5em" /></a>
              </div>
              <a href="https://drive.google.com/file/d/1Pdx8Xr85wsTODR7n0-mksswHKxbsUrqs/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                <button className="vvd"><span>Resume</span></button>
              </a>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}
